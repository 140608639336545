import { ReactNode } from "react";

import { RunState } from "types/generated";
import Tree, { TreeBranch, TreeBranchLink } from "ds/components/Tree";
import useTypedFlags from "hooks/useTypedFlags";
import Box from "ds/components/Box";
import RunSummarizationBox from "components/RunSummarization/RunSummarizationBox";
import { usePhaseSummary } from "components/RunSummarization/hooks/usePhaseSummary";
import RunSummarizationTrigger from "components/RunSummarization/RunSummarizationTrigger";

import RunPhaseLogsHooks from "./Hooks";
import RunLogs from "../RunLogs";
import styles from "./styles.module.css";

type RunPhaseLogsProps = {
  until: number | null;
  stateVersion: number | null;
  state: RunState;
  beforeHooks?: string[] | undefined;
  afterHooks?: string[] | undefined;
  children?: ReactNode;
  runId: string;
  stackId: string;
  isModuleRun: boolean;
};

const containersNotSupported = () => {
  // is safari
  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) return true;
  // firefox before version 133.0
  const version = navigator.userAgent.match(/firefox\/(\d+)/i)?.[1];
  return version && parseInt(version) < 133;
};

const RunPhaseLogs = ({
  beforeHooks,
  afterHooks,
  children,
  isModuleRun,
  ...rest
}: RunPhaseLogsProps) => {
  const { runSummarizationAi } = useTypedFlags();
  const {
    trigger,
    canTriggerSummary,
    date,
    showTrigger,
    summary,
    loading,
    showSummary,
    clearSummary,
    summaryContainerRef,
  } = usePhaseSummary(rest.stateVersion ?? 0);

  const logs = (
    <Box fullWidth direction="column" gap="large">
      <Box
        className={styles.logsContainer}
        direction={containersNotSupported() ? "column" : "row"}
        gap="x-large"
        fullWidth
      >
        <RunLogs isModuleRun={isModuleRun} {...rest} />

        {runSummarizationAi && showSummary && (
          <RunSummarizationBox
            ref={summaryContainerRef}
            loading={loading}
            content={summary}
            date={date}
            clearSummary={clearSummary}
          />
        )}
      </Box>
    </Box>
  );

  if (!beforeHooks?.length && !afterHooks?.length) {
    return (
      <>
        {logs}
        <Box gap="medium" align="start">
          {children}

          {runSummarizationAi && showTrigger && (
            <RunSummarizationTrigger onPress={() => trigger()} disabled={!canTriggerSummary}>
              AI summary
            </RunSummarizationTrigger>
          )}
        </Box>
      </>
    );
  }

  return (
    <Tree padding="0 0 0 medium">
      <RunPhaseLogsHooks type="before" hooks={beforeHooks} />

      <TreeBranch gap="medium" align="start">
        <TreeBranchLink position="top">{logs}</TreeBranchLink>

        <Box gap="medium" align="start">
          {children}

          {runSummarizationAi && showTrigger && (
            <RunSummarizationTrigger onPress={() => trigger()} disabled={showSummary}>
              AI summary
            </RunSummarizationTrigger>
          )}
        </Box>
      </TreeBranch>

      <RunPhaseLogsHooks type="after" hooks={afterHooks} />
    </Tree>
  );
};

export default RunPhaseLogs;
