import { Controller, useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Select from "ds/components/Select";
import Textarea from "ds/components/Textarea";
import { BlueprintInput, BlueprintInputType } from "types/generated";
import ToggleNew from "ds/components/ToggleNew";

import { TemplatePreviewFormFields } from "./types";

type TemplatePreviewFormFieldProps = {
  input: BlueprintInput;
};

const TemplatePreviewFormField = ({ input }: TemplatePreviewFormFieldProps) => {
  const { register, control } = useFormContext<TemplatePreviewFormFields>();

  switch (input.type) {
    case BlueprintInputType.ShortText: {
      return (
        <FormField key={input.id} label={input.name} helperText={input.description}>
          {({ ariaInputProps }) => <Input {...register(input.id)} {...ariaInputProps} />}
        </FormField>
      );
    }
    case BlueprintInputType.LongText: {
      return (
        <FormField key={input.id} label={input.name} helperText={input.description}>
          {({ ariaInputProps }) => <Textarea {...register(input.id)} {...ariaInputProps} />}
        </FormField>
      );
    }
    case BlueprintInputType.Secret: {
      return (
        <FormField key={input.id} label={input.name} helperText={input.description}>
          {({ ariaInputProps }) => (
            <Input type="password" {...register(input.id)} {...ariaInputProps} />
          )}
        </FormField>
      );
    }
    case BlueprintInputType.Number: {
      return (
        <FormField key={input.id} label={input.name} helperText={input.description}>
          {({ ariaInputProps }) => (
            <Input type="number" {...register(input.id)} {...ariaInputProps} />
          )}
        </FormField>
      );
    }
    case BlueprintInputType.Select: {
      return (
        <Controller
          name={input.id}
          control={control}
          render={({ field }) => (
            <FormField key={input.id} label={input.name} helperText={input.description}>
              {({ ariaInputProps }) => (
                <Select
                  autocomplete
                  value={field.value as string}
                  options={input.options.map((option) => ({ value: option, label: option }))}
                  onChange={field.onChange}
                  ariaInputProps={ariaInputProps}
                />
              )}
            </FormField>
          )}
        />
      );
    }
    case BlueprintInputType.Float: {
      return (
        <FormField key={input.id} label={input.name} helperText={input.description}>
          {({ ariaInputProps }) => (
            <Input type="number" {...register(input.id)} {...ariaInputProps} />
          )}
        </FormField>
      );
    }
    case BlueprintInputType.Boolean: {
      return (
        <Controller
          name={input.id}
          control={control}
          render={({ field }) => (
            <FormField key={input.id} label={input.name}>
              <ToggleNew
                variant="checkbox"
                id={input.id}
                onChange={field.onChange}
                isSelected={field.value as boolean}
                aria-label={
                  field.value
                    ? `Unselect ${input.description || input.name}`
                    : `Select ${input.description || input.name}`
                }
              >
                {input.description}
              </ToggleNew>
            </FormField>
          )}
        />
      );
    }
  }
};

export default TemplatePreviewFormField;
