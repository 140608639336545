import { useMutation } from "@apollo/client";
import { useCallback, useEffect, useMemo, useRef } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { RunContext } from "views/shared/run/Context";
import { Module, RunSummary, Stack } from "types/generated";
import { hasAtLeastStackWriteAccess } from "shared/Stack/utils";
import { hasAtLeastModuleWriteAccess } from "shared/Module/utils";
import useAnalytics from "hooks/useAnalytics";

import { GET_RUN_SUMMARIZATION } from "./gql";

const isStack = (stack: Stack | Module): stack is Stack => (stack as Stack).__typename === "Stack";

export const useRunSummary = () => {
  const { onError } = useTypedContext(FlashContext);
  const { run, stack: stackOrModule } = useTypedContext(RunContext);
  const summaryContainerRef = useRef<HTMLDivElement>(null);
  const trackSegmentAnalyticsEvent = useAnalytics();

  const [trigger, { data, error, loading, reset }] = useMutation<{
    summarizeRun: RunSummary;
  }>(GET_RUN_SUMMARIZATION, {
    refetchQueries: ["GetRun"],
    variables: { stack: stackOrModule.id, run: run.id },
    onError,
  });

  const { summary, date, summarizable } = useMemo(() => {
    return {
      summary: run.summary?.summary ?? data?.summarizeRun.summary,
      date: run.summary?.createdAt ?? data?.summarizeRun.createdAt,
      summarizable: run.summarizable,
    };
  }, [run, data?.summarizeRun]);

  const clearSummary = useCallback(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    const el = summaryContainerRef.current;
    if (!el) return;

    const handleCopy = () => {
      if (!document.getSelection()?.toString()) {
        return;
      }

      trackSegmentAnalyticsEvent("Run Logs - Suggestion Code Snippets Copied", {
        runId: run.id,
        runState: run.state,
        stackId: stackOrModule.id,
      });
    };

    el.addEventListener("copy", handleCopy);

    return () => {
      el.removeEventListener("copy", handleCopy);
    };
  }, [run.id, run.state, stackOrModule, trackSegmentAnalyticsEvent]);

  const triggerSummary = () => {
    trackSegmentAnalyticsEvent("Run Logs - AI Run Suggestions Clicked", {
      stackId: stackOrModule.id,
      runId: run.id,
      runState: run.state,
    });
    trigger();
  };

  const canTriggerSummary = isStack(stackOrModule)
    ? hasAtLeastStackWriteAccess(stackOrModule)
    : hasAtLeastModuleWriteAccess(stackOrModule);
  const showSummary = !!summary || loading;
  const showTrigger = summarizable && !showSummary;

  return {
    trigger: triggerSummary,
    showTrigger,
    canTriggerSummary,
    summary,
    date,
    error,
    loading,
    showSummary,
    clearSummary,
    summaryContainerRef,
  };
};
