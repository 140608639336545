import { createContext, ReactNode, useEffect } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import useLocalStorage from "@rehooks/local-storage";

import { SyntaxTheme } from "types/SyntaxTheme";

import { COLOR_THEME_LOCAL_STORAGE_KEY } from "./constants";

export type ThemePalette = "light" | "dark";

type ThemeContextProps = {
  changeTheme: (theme: ThemePalette) => void;
  currentTheme: ThemePalette;
  isDarkMode: boolean;
  syncThemeWithSystem: boolean;
  setSyncThemeWithSystem: (value: boolean) => void;
  currentLightModeSyntaxTheme: SyntaxTheme;
  currentDarkModeSyntaxTheme: SyntaxTheme;
  setCurrentLightModeSyntaxTheme: (value: SyntaxTheme) => void;
  setCurrentDarkModeSyntaxTheme: (value: SyntaxTheme) => void;
};

export const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);
ThemeContext.displayName = "ThemeContext";

type ThemeProps = {
  children?: ReactNode;
  forcedTheme?: ThemePalette;
};

const Theme = ({ children, forcedTheme }: ThemeProps) => {
  const [storageTheme, setCurrentTheme] = useLocalStorage<ThemePalette>(
    COLOR_THEME_LOCAL_STORAGE_KEY
  );
  const [syncThemeWithSystem, setSyncThemeWithSystem] =
    useLocalStorage<boolean>("syncThemeWithSystem");

  const [storageCurrentLightModeSyntaxTheme, setCurrentLightModeSyntaxTheme] =
    useLocalStorage<SyntaxTheme>("lightModeSyntaxTheme");
  const [storageCurrentDarkModeSyntaxTheme, setCurrentDarkModeSyntaxTheme] =
    useLocalStorage<SyntaxTheme>("darkModeSyntaxTheme");

  const currentTheme = forcedTheme || storageTheme || "light";

  const currentLightModeSyntaxTheme = storageCurrentLightModeSyntaxTheme || SyntaxTheme.Light;
  const currentDarkModeSyntaxTheme = storageCurrentDarkModeSyntaxTheme || SyntaxTheme.Dark;

  const systemThemeChangeHandler = (e: MediaQueryListEvent) => {
    toggleThemeByAutoMode(e.matches);
  };

  const toggleThemeByAutoMode = (matches: boolean) => {
    if (syncThemeWithSystem) {
      const newColorScheme = matches ? "dark" : "light";

      setCurrentTheme(newColorScheme);
    }
  };

  useEffect(() => {
    document.querySelector("html")?.setAttribute("data-theme", currentTheme);
  }, [currentTheme]);

  useEffect(() => {
    const themeMatch = window.matchMedia("(prefers-color-scheme:dark)");
    themeMatch.addEventListener("change", systemThemeChangeHandler);

    toggleThemeByAutoMode(themeMatch.matches);

    return () => {
      themeMatch.removeEventListener("change", systemThemeChangeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncThemeWithSystem]);

  return (
    <ThemeContext.Provider
      value={{
        changeTheme: setCurrentTheme,
        currentTheme,
        isDarkMode: currentTheme === "dark",
        syncThemeWithSystem: !!syncThemeWithSystem,
        setSyncThemeWithSystem,
        currentLightModeSyntaxTheme,
        currentDarkModeSyntaxTheme,
        setCurrentLightModeSyntaxTheme,
        setCurrentDarkModeSyntaxTheme,
      }}
    >
      <SkeletonTheme
        baseColor="var(--semantic-color-background-secondary)"
        highlightColor="var(--semantic-color-background-app-background)"
      >
        {children}
      </SkeletonTheme>
    </ThemeContext.Provider>
  );
};

export default Theme;
