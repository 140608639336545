import useTypedFlags from "hooks/useTypedFlags";
import { useRunSummary } from "components/RunSummarization/hooks/useRunSummary";
import RunSummarizationTrigger from "components/RunSummarization/RunSummarizationTrigger";
import RunSummarizationBox from "components/RunSummarization/RunSummarizationBox";
import Box from "ds/components/Box";

import PerformTaskAgainButton from "../PerformTaskAgainButton";
import RetryRunButton from "../RetryRunButton";

type UnfinishedRunActionsProps = {
  stackId: string;
  runId: string;
  canRetry: boolean;
  runRetryBlocker: string | null;
  isTaskRun: boolean;
  isModuleRun: boolean;
  canPerformAgain: boolean;
  runTaskCommand: string | null;
};

const UnfinishedRunActions = ({
  stackId,
  runId,
  canRetry,
  runRetryBlocker,
  isTaskRun,
  isModuleRun,
  canPerformAgain,
  runTaskCommand,
}: UnfinishedRunActionsProps) => {
  const { runSummarizationAi } = useTypedFlags();
  const {
    trigger,
    showTrigger,
    summary,
    canTriggerSummary,
    date,
    loading,
    showSummary,
    clearSummary,
    summaryContainerRef,
  } = useRunSummary();

  return (
    <>
      <Box gap="medium">
        {canPerformAgain && <PerformTaskAgainButton command={runTaskCommand} stackId={stackId} />}
        {(canRetry || (runRetryBlocker && !isTaskRun && !isModuleRun)) && (
          <RetryRunButton runId={runId} stackId={stackId} runRetryBlocker={runRetryBlocker} />
        )}
        {runSummarizationAi && showTrigger && (
          <RunSummarizationTrigger onPress={trigger} disabled={!canTriggerSummary}>
            AI assist
          </RunSummarizationTrigger>
        )}
      </Box>
      {runSummarizationAi && showSummary && (
        <RunSummarizationBox
          ref={summaryContainerRef}
          content={summary}
          loading={loading}
          date={date}
          clearSummary={clearSummary}
        />
      )}
    </>
  );
};

export default UnfinishedRunActions;
