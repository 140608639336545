import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";

import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import useErrorHandle from "hooks/useErrorHandle";
import Box from "ds/components/Box";
import FormToggleField from "ds/components/Form/ToggleField";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import PageLayout from "./PageLayout";
import { GET_AI_SETTINGS, TOGGLE_AI } from "./gql";
import { showAcceptToSModal } from "./AcceptToSModal";

const AISettings = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  useTitle(`Organization Settings · Artificial Intelligence · ${accountName}`);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationAi,
  });

  const {
    data,
    loading: pageLoading,
    error,
  } = useQuery<{
    hasAIEnabled: boolean;
    acceptedTermsAndConditionsAI: boolean;
  }>(GET_AI_SETTINGS, {
    fetchPolicy: "cache-and-network",
    onError,
  });

  const [toggleAI, { loading: toggleAILoading }] = useMutation<{
    accountToggleEnablingAI: boolean;
  }>(TOGGLE_AI, {
    onError,
    refetchQueries: ["GetAISettings"],
  });

  const handleToggleAI = useCallback(async () => {
    try {
      const { data: toggleResult, errors } = await toggleAI();
      if (errors?.length) throw errors;
      trackSegmentAnalyticsEvent("AI Summarization Toggled", {
        status: toggleResult?.accountToggleEnablingAI ? "enabled" : "disabled",
      });
      reportSuccess({ message: "AI settings updated successfully" });
    } catch (e) {
      onError(e);
    }
  }, [onError, reportSuccess, toggleAI, trackSegmentAnalyticsEvent]);

  const ErrorContent = useErrorHandle(error);

  const enableAIAssistField = useCallback(() => {
    if (!data) return;

    // AI is already enabled
    if (data.hasAIEnabled) {
      handleToggleAI();
      return;
    }

    // AI is not enabled, but the user has already accepted ToS
    if (data.acceptedTermsAndConditionsAI) {
      handleToggleAI();
      return;
    }

    // AI is not enabled, and the user has not accepted ToS
    showAcceptToSModal().then((accepted) => {
      if (accepted) {
        handleToggleAI();
      }
    });
  }, [data, handleToggleAI]);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (pageLoading && !data) {
    return <PageLoading />;
  }

  if (!data) {
    return <NotFoundPage />;
  }

  return (
    <PageLayout>
      <Box direction="column" fullWidth>
        <Box align="start" justify="between" fullWidth>
          <FormToggleField
            variant="switch"
            onChange={enableAIAssistField}
            checked={data.hasAIEnabled}
            disabled={toggleAILoading}
            title="AI Assist"
            description="Enable AI Assistance after a phase completes to summarize key log decisions and exceptions for easy review."
          />
        </Box>
      </Box>
    </PageLayout>
  );
};

export default AISettings;
