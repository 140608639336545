import { Route, Navigate, Routes } from "react-router-dom";

import Layout from "components/layout/Layout";
import NotFoundPage from "components/error/NotFoundPage";
import useFavicon from "hooks/useFavicon";
import { isSaasDistribution } from "utils/distribution";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import Stack from "views/Stack";
import useNavigationDefaultView from "hooks/useNavigationDefaultView";
import useTypedFlags from "hooks/useTypedFlags";

import BillingForm from "./Settings/billing/UpgradeForm";
import BillingInvoiceForm from "./Settings/billing/InvoiceForm";
import CloudIntegrations from "./CloudIntegrations/CloudIntegrations";
import NewPolicy from "./NewPolicy";
import NewWebhook from "./NewWebhook";
import Settings from "./Settings/Settings";
import StacksNew from "./StacksNew";
import WorkerPool from "./WorkerPool";
import WorkerPools from "./WorkerPools";
import VCSAgentPools from "./VCSAgentPools";
import VCSAgentPool from "./VCSAgentPool";
import NewVCSAgentPool from "./NewVCSAgentPool";
import Resources from "./Resources";
import Runs from "./Runs";
import Notifications from "./Notifications";
import Spaces from "./Spaces";
import Webhooks from "./Webhooks";
import Webhook from "./Webhook";
import EditWebhook from "./EditWebhook";
import Blueprints from "./Blueprints";
import BlueprintPage from "./Blueprint";
import PersonalSettings from "./PersonalSettings";
import LoginPolicyCreate from "./LoginPolicyCreate";
import LoginPolicyUpdate from "./LoginPolicyDetails";
import Providers from "./TerraformRegistry/Providers";
import GpgKeys from "./TerraformRegistry/GpgKeys";
import Provider from "./TerraformRegistry/Provider";
import ManageSlackAccess from "./Settings/ManageSlackAccess";
import Usage from "./Usage";
import NewContext from "./NewContext";
import Contexts from "./Contexts";
import Context from "./Context";
import NewStack from "./NewStack";
import VCSIntegrations from "./VCS/Integrations";
import VCSIntegration from "./VCS/Integration";
import { VCS_INTEGRATION_PATH, VCS_INTEGRATIONS_PATH } from "./VCS/constants";
import PoliciesTemplates from "./Policies/Templates";
import PoliciesList from "./Policies/List";
import PolicyDetails from "./PolicyDetails";
import LaunchPad from "./LaunchPad";
import NewModule from "./NewModule";
import Modules from "./Modules";
import SharedModules from "./SharedModules";
import Dashboard from "./Dashboard";
import ModuleSettingsNew from "./ModuleSettings";
import ModuleNew from "./Module";
import { isV4BillingTier } from "./Settings/BillingNew/utils";
import { SubscriptionContext } from "./SubscriptionWrapper";
import { AuditTrail } from "./Settings/AuditTrail";
import AuditTrailEntries from "./Settings/AuditTrail/Entries";
import AuditTrailConfig from "./Settings/AuditTrail/Config";

const Account = () => {
  const isSaas = isSaasDistribution();

  const { billingUsage, auditTrailLogsFrontend, useBillingV4 } = useTypedFlags();

  const { viewer } = useTypedContext(AccountContext);

  const { tier } = useTypedContext(SubscriptionContext);

  const isV4Tier = isV4BillingTier(tier);

  useFavicon();

  const defaultView = useNavigationDefaultView(viewer.admin);

  return (
    <Layout>
      <Routes>
        <Route index element={<Navigate to={defaultView.config.to} replace />} />

        {viewer.admin && <Route path="/dashboard" element={<Dashboard />} />}
        <Route path="/stacks" element={<StacksNew />} />

        <Route path="/stack/:stackId/*" element={<Stack />} />
        <Route path="/context/:contextId/*" element={<Context />} />
        <Route path="/contexts" element={<Contexts />} />

        <Route path="/policies" element={<PoliciesList />} />

        <Route path="/policies-templates" element={<PoliciesTemplates />} />

        <Route path="/policy/:policyId/*" element={<PolicyDetails />} />

        {isSaas && !isV4Tier && !useBillingV4 && (
          <Route path="/billing/form" element={<BillingForm />} />
        )}
        {isSaas && !isV4Tier && <Route path="/billing/invoice" element={<BillingInvoiceForm />} />}

        <Route path="/settings/integrations/manage-slack-access" element={<ManageSlackAccess />} />

        <Route path="/settings/*" element={<Settings />} />
        <Route path="/provider/:providerId" element={<Provider />} />
        <Route path="/providers" element={<Providers />} />
        <Route path="/gpg-keys" element={<GpgKeys />} />
        <Route path="/shared-modules" element={<SharedModules />} />
        <Route path="/modules" element={<Modules />} />
        <Route path="/module/:moduleId/settings/*" element={<ModuleSettingsNew />} />
        <Route path="/module/:moduleId/*" element={<ModuleNew />} />
        <Route path="/new/context" element={<NewContext />} />
        <Route path="/new/module" element={<NewModule />} />
        <Route path="/new/policy" element={<NewPolicy />} />

        <Route path="/new/login-policy" element={<LoginPolicyCreate />} />

        <Route path="/new/stack" element={<NewStack />} />

        <Route path="/new/webhook" element={<NewWebhook />} />

        <Route path="/resources/*" element={<Resources />} />
        <Route path="/runs" element={<Runs />} />
        <Route path="/worker-pool/:workerPoolId/*" element={<WorkerPool />} />

        <Route path="/worker-pools" element={<WorkerPools />} />

        {isSaas && <Route path="/vcs-agent-pools" element={<VCSAgentPools />} />}
        {isSaas && <Route path="/new/vcs-agent-pool" element={<NewVCSAgentPool />} />}
        {isSaas && <Route path="/vcs-agent-pool/:vcsAgentPoolId" element={<VCSAgentPool />} />}
        <Route path="/login-policy/:policyId" element={<LoginPolicyUpdate />} />

        <Route path="/cloud-integrations/*" element={<CloudIntegrations />} />
        <Route path="/notifications" element={<Notifications />} />

        <Route path="/webhook/:webhookId/edit" element={<EditWebhook />} />
        <Route path="/webhook/:webhookId/*" element={<Webhook />} />

        <Route path="/webhooks" element={<Webhooks />} />
        <Route path="/spaces/:spaceId" element={<Spaces />} />
        <Route path="/spaces" element={<Spaces />} />

        <Route path="/blueprint/:blueprintId" element={<BlueprintPage />} />
        <Route path="/blueprints" element={<Blueprints />} />
        <Route path="/personal-settings/*" element={<PersonalSettings />} />

        {isSaas && billingUsage && <Route path="/usage/*" element={<Usage />} />}

        <Route path={`${VCS_INTEGRATIONS_PATH}/*`} element={<VCSIntegrations />} />

        <Route path={VCS_INTEGRATION_PATH} element={<VCSIntegration />} />

        {viewer.admin && <Route path="/launchpad" element={<LaunchPad />} />}

        {viewer.admin && auditTrailLogsFrontend && (
          <Route path="/audit-trail" element={<AuditTrail />}>
            <Route index element={<AuditTrailEntries />} />
            <Route path="config" element={<AuditTrailConfig />} />
          </Route>
        )}

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Layout>
  );
};

export default Account;
