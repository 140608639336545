import { useEffect, useState } from "react";

import { Moon, Sun } from "components/icons/generated";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import Toggle from "ds/components/Toggle";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { SyntaxTheme } from "types/SyntaxTheme";
import { ThemeContext, ThemePalette } from "views/Theme";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import IconTile from "ds/components/IconTile";
import TileTitle from "ds/components/Tile/Title";
import TileIndicator from "ds/components/Tile/Indicator";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import { updateSegmentUser, updateUserflowUser } from "shared/Analytics";

import { AVAILABLE_SYNTAX_THEMES } from "./constants";
import styles from "./styles.module.css";
import ThemeAppearanceSectionTitle from "./SectionTitle";
import { getSyntaxThemeDescription, getSyntaxThemeIcon, getSyntaxThemeTitle } from "./utils";
import ThemeAppearanceSyntaxTile from "./SyntaxTile";

const ThemeAppearance = () => {
  const {
    isDarkMode,
    currentTheme,
    changeTheme,
    syncThemeWithSystem,
    setSyncThemeWithSystem,
    currentLightModeSyntaxTheme,
    currentDarkModeSyntaxTheme,
    setCurrentLightModeSyntaxTheme,
    setCurrentDarkModeSyntaxTheme,
  } = useTypedContext(ThemeContext);

  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Personal Settings · Theme appearance · ${accountName}`);

  const [currentThemeVisibleToConfigure, setCurrentThemeVisibleToConfigure] =
    useState<ThemePalette>(isDarkMode ? "dark" : "light");

  const isSyntaxThemeActive = (syntaxTheme: SyntaxTheme) => {
    if (currentThemeVisibleToConfigure === "dark") {
      return currentDarkModeSyntaxTheme === syntaxTheme;
    }

    return currentLightModeSyntaxTheme === syntaxTheme;
  };

  const handleSyntaxThemeChange = (syntaxTheme: SyntaxTheme) => () => {
    if (currentThemeVisibleToConfigure === "dark") {
      setCurrentDarkModeSyntaxTheme(syntaxTheme);
    } else {
      setCurrentLightModeSyntaxTheme(syntaxTheme);
    }
  };

  const handleLightMode = () => {
    if (!syncThemeWithSystem) {
      updateUserflowUser("colorTheme", "light");
      updateSegmentUser("colorTheme", "light");
      changeTheme("light");
    }

    setCurrentThemeVisibleToConfigure("light");
  };

  const handleDarkMode = () => {
    if (!syncThemeWithSystem) {
      updateUserflowUser("colorTheme", "dark");
      updateSegmentUser("colorTheme", "dark");
      changeTheme("dark");
    }

    setCurrentThemeVisibleToConfigure("dark");
  };

  const toggleSystemMode = () => {
    setSyncThemeWithSystem(!syncThemeWithSystem);
  };

  useEffect(() => {
    // sync the visible and active theme when the system mode is enabled
    if (syncThemeWithSystem && currentThemeVisibleToConfigure !== currentTheme) {
      setCurrentThemeVisibleToConfigure(isDarkMode ? "dark" : "light");
    }
  }, [syncThemeWithSystem, isDarkMode, currentTheme, currentThemeVisibleToConfigure]);

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle tag="h2">Theme appearance</ViewHeaderTitle>
      </ViewHeader>

      <Box direction="column" grow="1" className={styles.wrapper} padding="large" fullWidth>
        <Box direction="column" fullWidth>
          <Typography variant="p-t5" tag="h2" margin="0 0 small 0">
            Theme mode
          </Typography>

          <Typography variant="p-body2" tag="p" color="secondary">
            Choose how Spacelift looks to you.
          </Typography>

          <FormField helperText="Automatically switch between light and dark modes.">
            <Toggle variant="switch" onChange={toggleSystemMode} checked={syncThemeWithSystem}>
              Sync with system
            </Toggle>
          </FormField>

          {!syncThemeWithSystem && (
            <Box direction="row" fullWidth gap="large" margin="large 0 0 0" limitWidth="medium">
              <Box className={styles.modeTile} fullWidth>
                <TileWrapper selected={!isDarkMode} onClick={handleLightMode}>
                  <Box align="start" justify="between" grow="1">
                    <TileContent direction="row" align="center">
                      <IconTile icon={Sun} variant={!isDarkMode ? "primary" : "secondary"} />
                      <TileTitle>Light mode</TileTitle>
                    </TileContent>
                    <TileIndicator indicator="radio" selected={!isDarkMode} />
                  </Box>
                </TileWrapper>
              </Box>
              <Box className={styles.modeTile} fullWidth>
                <TileWrapper selected={isDarkMode} onClick={handleDarkMode}>
                  <Box align="start" justify="between" grow="1">
                    <TileContent direction="row" align="center">
                      <IconTile icon={Moon} variant={isDarkMode ? "primary" : "secondary"} />
                      <TileTitle>Dark mode</TileTitle>
                    </TileContent>
                    <TileIndicator indicator="radio" selected={isDarkMode} />
                  </Box>
                </TileWrapper>
              </Box>
            </Box>
          )}
        </Box>

        {!syncThemeWithSystem && (
          <Box direction="column" margin="large 0 0 0" padding="medium 0 0 0" limitWidth="medium">
            <Box direction="column" fullWidth padding="large" className={styles.syntaxThemesList}>
              <ThemeAppearanceSectionTitle
                icon={getSyntaxThemeIcon(currentThemeVisibleToConfigure)}
                title={`Syntax ${getSyntaxThemeTitle(currentThemeVisibleToConfigure)}`}
                description={getSyntaxThemeDescription(currentThemeVisibleToConfigure)}
              />

              <Box direction="row" gap="large" fullWidth wrap className={styles.syntaxWrapper}>
                {AVAILABLE_SYNTAX_THEMES.map((syntaxTheme) => (
                  <ThemeAppearanceSyntaxTile
                    selected={isSyntaxThemeActive(syntaxTheme.key)}
                    onClick={handleSyntaxThemeChange(syntaxTheme.key)}
                    key={syntaxTheme.key}
                    themeKey={syntaxTheme.key}
                    title={syntaxTheme.title}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        )}

        {syncThemeWithSystem && (
          <Box margin="large 0 0 0" gap="large" limitWidth="medium">
            <Box direction="column" fullWidth>
              <Box direction="column" fullWidth padding="large" className={styles.syntaxThemesList}>
                <ThemeAppearanceSectionTitle
                  icon={Sun}
                  title="Syntax for Day theme"
                  isActive={!isDarkMode}
                  description={getSyntaxThemeDescription("light")}
                />

                <Box direction="row" gap="large" fullWidth wrap className={styles.syntaxWrapper}>
                  {AVAILABLE_SYNTAX_THEMES.map((syntaxTheme) => (
                    <ThemeAppearanceSyntaxTile
                      selected={currentLightModeSyntaxTheme === syntaxTheme.key}
                      onClick={() => setCurrentLightModeSyntaxTheme(syntaxTheme.key)}
                      key={syntaxTheme.key}
                      themeKey={syntaxTheme.key}
                      title={syntaxTheme.title}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Box direction="column" fullWidth>
              <Box direction="column" fullWidth padding="large" className={styles.syntaxThemesList}>
                <ThemeAppearanceSectionTitle
                  icon={Moon}
                  title="Syntax for Night theme"
                  isActive={isDarkMode}
                  description={getSyntaxThemeDescription("dark")}
                />

                <Box direction="row" gap="large" fullWidth wrap className={styles.syntaxWrapper}>
                  {AVAILABLE_SYNTAX_THEMES.map((syntaxTheme) => (
                    <ThemeAppearanceSyntaxTile
                      selected={currentDarkModeSyntaxTheme === syntaxTheme.key}
                      onClick={() => setCurrentDarkModeSyntaxTheme(syntaxTheme.key)}
                      key={syntaxTheme.key}
                      themeKey={syntaxTheme.key}
                      title={syntaxTheme.title}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ThemeAppearance;
