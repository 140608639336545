import { NetworkStatus, useQuery } from "@apollo/client";
import { useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";

import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import FlashContext from "components/FlashMessages/FlashContext";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import PageLoading from "components/loading/PageLoading";
import Button from "ds/components/Button";
import { Space, Clock } from "components/icons/generated";
import PolicyDetailsManageDropdown from "views/Account/PolicyDetails/ManageDropdown";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Timestamp from "components/time/Timestamp";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import NotFoundPage from "components/error/NotFoundPage";
import PolicyTypeBadge from "components/PolicyTypeBadge";
import { PolicyType } from "types/generated";
import Tab from "ds/components/Tab";
import TabWithCounter from "ds/components/Tab/WithCounter";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import { getDocsUrl } from "utils/getDocsUrl";
import Callout from "ds/components/Callout";
import FeedbackActions from "ds/components/Feedback/Actions";
import { POLICY_TYPES_FEATURES, POLICY_TYPES_TITLES } from "constants/policy";
import useErrorHandle from "hooks/useErrorHandle";
import { hasSpaceManageOrWriteAccess } from "utils/user";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";
import DocumentationIconButton from "components/DocumentationIconButton";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import ButtonNew from "ds/components/Button/New";

import { GET_POLICY } from "./gql";
import PolicyDetailsBody from "./Body";
import { PolicyQueryResponse } from "./types";
import PolicyDetailsUsedBy from "./UsedBy";
import { showPolicyDetailsDrawer } from "./Drawer";
import { FILTERS_ORDER_SETTINGS_KEY } from "../Policies/List/constants";
import { SubscriptionContext } from "../SubscriptionWrapper";
import { showPolicyEditDrawer } from "../PolicyEditDrawer";
import useTakenPolicyNames from "../Policies/useTakenPolicyNames";

const PolicyDetails = () => {
  const { accountName } = useTypedContext(AccountContext);

  const { tierFeatures } = useTypedContext(SubscriptionContext);

  useTitle(`Policy details · ${accountName}`);

  const { onError } = useTypedContext(FlashContext);
  const params = useParams<{ policyId?: string }>();

  const { policyId } = params;

  const [body, setBody] = useState("");

  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);

  const takenPolicyNames = useTakenPolicyNames();

  const handleOpenEditDrawer = () => {
    if (canManagePolicy) {
      showPolicyEditDrawer({
        policy: data?.policy,
        analyticsPage: AnalyticsPagePolicy.PolicyDetails,
        takenPolicyNames,
        refetchQueries: [
          {
            query: GET_POLICY,
            variables: {
              policyId: data?.policy.id,
            },
          },
        ],
      });
    }
  };

  const handleOpenFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(true);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(false);
  };

  const {
    loading: isPolicyLoading,
    data,
    networkStatus,
    error,
  } = useQuery<PolicyQueryResponse>(GET_POLICY, {
    onError,
    variables: {
      policyId,
    },
    onCompleted: (data) => {
      setBody(data.policy.body);
    },
  });

  const openDetailsDrawer = () => {
    if (data?.policy) {
      showPolicyDetailsDrawer({
        policy: data.policy,
        id: data.policy.id,
        onOpenFullDescription: handleOpenFullDescriptionDrawer,
      });
    }
  };

  const policyType = data?.policy?.type;
  const requiredTierFeature = policyType && POLICY_TYPES_FEATURES[policyType];

  const isDisabledPolicy = requiredTierFeature && !tierFeatures.includes(requiredTierFeature);

  useBreadcrumbs(
    [
      {
        title: "Policies",
        link: getBreadcrumbsBackUrl("/policies", FILTERS_ORDER_SETTINGS_KEY),
      },
      {
        title: data?.policy?.name || "",
      },
    ],
    [data?.policy?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (isPolicyLoading && networkStatus !== NetworkStatus.refetch && !data?.policy) {
    return <PageLoading />;
  }

  if (!policyId || !data?.policy) {
    return <NotFoundPage />;
  }

  const policyUrl = `/policy/${data.policy.id}`;
  const policyUsedByUrl = `${policyUrl}/used-by`;
  const canManagePolicy = hasSpaceManageOrWriteAccess(data.policy.spaceDetails.accessLevel);

  const isBodyReadyToUpdate = data.policy.body !== body;

  return (
    <>
      {isDisabledPolicy && (
        <Callout variant="danger">
          {`${POLICY_TYPES_TITLES[policyType]} policy will not be evaluated for your account. Upgrade your plan to make use of it.`}
          <FeedbackActions>
            <Button to="/settings/billing" variant="contrast" size="small">
              Upgrade
            </Button>
          </FeedbackActions>
        </Callout>
      )}
      <ViewHeader
        backConfirmation={
          isBodyReadyToUpdate ? "Your changes in policy body will not be saved." : undefined
        }
      >
        <ViewHeaderWrapper direction="row" align="center" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle>{data.policy.name}</ViewHeaderTitle>
            <PolicyTypeBadge type={data.policy.type} />
          </ViewHeaderWrapper>
          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <DocumentationIconButton
              href={getDocsUrl("/concepts/policy")}
              tooltipText="Go to policies documentation"
            />
            <ButtonNew variant="secondary" onPress={openDetailsDrawer}>
              Details
            </ButtonNew>
            <PolicyDetailsManageDropdown
              name={data.policy.name}
              id={data.policy.id}
              handlePolicyEditDetailsClick={handleOpenEditDrawer}
              size="medium"
              canManagePolicies={canManagePolicy}
              hasAttachedEntities={!!data.policy.hasAttachedStacks}
            />
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>

        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            <MetaInfoListItem
              icon={Space}
              linkText={data.policy.spaceDetails.name}
              href={`/spaces/${data.policy.spaceDetails.id}`}
            />
            <MetaInfoListItem icon={Clock}>
              <Timestamp prefix="Updated " timestamp={data.policy.updatedAt} />
            </MetaInfoListItem>
          </MetaInfoList>
        </ViewHeaderScrollCollapse>

        {data.policy.type !== PolicyType.Notification && (
          <ViewHeaderNavigation>
            <Tab end to={policyUrl} label="Policy body" />

            <TabWithCounter
              count={data.policy.attachedStacks.length}
              to={policyUsedByUrl}
              label="Used by"
            />
          </ViewHeaderNavigation>
        )}
      </ViewHeader>
      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={data.policy.description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
      />

      <Routes>
        <Route
          index
          element={
            <PolicyDetailsBody
              policy={data.policy}
              body={body}
              setBody={setBody}
              isBodyReadyToUpdate={isBodyReadyToUpdate}
            />
          }
        />
        {data.policy.type !== PolicyType.Notification && (
          <Route
            path="used-by"
            element={
              <PolicyDetailsUsedBy
                attachedStacks={data.policy.attachedStacks}
                loading={isPolicyLoading}
              />
            }
          />
        )}

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default PolicyDetails;
