import cx from "classnames";

import ButtonNew from "ds/components/Button/New";
import { SparksFilled } from "components/icons/generated";

import styles from "./styles.module.css";

type RunSummarizationTriggerProps = {
  className?: string;
  disabled?: boolean;
  size?: "small" | "medium";
  onPress: () => void;
  children: string;
};

const RunSummarizationTrigger = ({
  className,
  children,
  disabled,
  size = "small",
  onPress,
}: RunSummarizationTriggerProps) => {
  return (
    <ButtonNew
      className={cx(styles.button, className)}
      startIcon={SparksFilled}
      disabled={disabled}
      size={size}
      variant="contrast"
      onPress={onPress}
    >
      {children}
    </ButtonNew>
  );
};

export default RunSummarizationTrigger;
