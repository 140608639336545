import { gql } from "@apollo/client";

export const GET_AI_SETTINGS = gql`
  query GetAISettings {
    acceptedTermsAndConditionsAI
    hasAIEnabled
  }
`;

export const TOGGLE_AI = gql`
  mutation ToggleAI {
    accountToggleEnablingAI
  }
`;

export const ACCEPT_AI_TERMS = gql`
  mutation AcceptAITerms {
    accountAcceptTermsAndConditionsForAI
  }
`;
