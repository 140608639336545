import { ReactNode } from "react";

import FullScreenModalWrapper from "./Wrapper";
import FullScreenModalHeader from "./Header";

type FullScreenModalProps = {
  title: string;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  id?: string;
};

const FullScreenModal = ({ title, onClose, children, className }: FullScreenModalProps) => {
  return (
    <FullScreenModalWrapper className={className}>
      <FullScreenModalHeader onClose={onClose}>{title}</FullScreenModalHeader>
      {children}
    </FullScreenModalWrapper>
  );
};

FullScreenModal.displayName = "DS.FullScreenModal";

export default FullScreenModal;
