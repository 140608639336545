import { ChangeEvent, MouseEvent, Ref, useCallback, useId } from "react";
import cx from "classnames";

import { TypographyColors } from "types/Colors";

import DropdownSectionItem from "../Dropdown/SectionItem";
import TextEllipsis from "../TextEllipsis";
import styles from "./styles.module.css";
import Toggle from "../Toggle";
import Typography from "../Typography";
import { SelectOptionHighlight, SelectOption as SelectOptionType } from "./types";
import SelectOptionHighlights from "./OptionHighlights";
import { TooltipProps } from "../Tooltip/types";

const getTickboxLabel = (isSwitch: boolean, isChecked: boolean) => {
  if (isSwitch) {
    return isChecked ? "Switch off" : "Switch on";
  }

  return isChecked ? "Unselect" : "Select";
};

type SelectOptionProps = {
  value: string;
  label: string;
  postfix?: string | number;
  postFixColor?: TypographyColors;
  onChange?: (value: string, option?: SelectOptionType) => void;
  closeSelect?: () => void;
  // TODO: the `multiple` property is used for Dropdowns with multiple options, should be refactored and moved to DS Dropdown
  multiple?: boolean;
  checked?: boolean;
  className?: string;
  textClassName?: string;
  innerRef?: Ref<HTMLElement>;
  disabled?: boolean;
  tooltipPlacement?: TooltipProps["placement"];
  tooltipWidthMode?: TooltipProps["widthMode"];
  switchToggle?: boolean;
  searchHighlightResult?: SelectOptionHighlight;
  highlightSelected?: boolean;
  textEllipsisMode?: "single" | "line-clamp";
  emptyDisplayLabel?: string;
};

const SelectOption = (props: SelectOptionProps) => {
  const {
    disabled,
    value,
    label,
    onChange,
    closeSelect,
    multiple,
    checked,
    postfix,
    postFixColor,
    className,
    textClassName,
    innerRef,
    tooltipPlacement = "top-end",
    tooltipWidthMode = "maxWidthXl",
    switchToggle,
    searchHighlightResult,
    highlightSelected = false,
    textEllipsisMode = "single",
    emptyDisplayLabel = "Empty",
  } = props;

  // If the label comes as an empty string (""), it is replaced with a visual placeholder.
  const displayLabel = label || emptyDisplayLabel;

  const id = useId();

  const handleOnChange = useCallback(
    (e?: ChangeEvent<HTMLInputElement> | MouseEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (disabled) return false;

      onChange?.(value, {
        value,
        label,
      });

      if (!multiple) {
        closeSelect?.();
      }

      return undefined;
    },
    [closeSelect, disabled, label, multiple, onChange, value]
  );

  // TODO(accessibility): use label instead of button for list items
  return (
    <DropdownSectionItem
      active={highlightSelected && checked}
      onClick={handleOnChange}
      innerRef={innerRef}
      className={className}
    >
      {multiple && (
        <Toggle
          variant={switchToggle ? "switch" : "checkbox"}
          id={id}
          onChange={handleOnChange}
          checked={checked}
          className={styles.tickbox}
          disabled={disabled}
          ariaLabel={getTickboxLabel(!!switchToggle, !!checked)}
        />
      )}

      <TextEllipsis
        tooltip={displayLabel}
        tooltipPlacement={tooltipPlacement}
        tooltipWidthMode={tooltipWidthMode}
        delay={400}
        mode={textEllipsisMode}
      >
        {(ellipsisProps) => (
          <Typography
            {...ellipsisProps}
            variant="p-body3"
            tag="span"
            className={cx(ellipsisProps.className, textClassName)}
          >
            <SelectOptionHighlights highlights={searchHighlightResult} fullText={displayLabel} />
          </Typography>
        )}
      </TextEllipsis>

      {typeof postfix !== "undefined" && (
        <Typography variant="p-body3" tag="span" className={styles.postfix} color={postFixColor}>
          {postfix}
        </Typography>
      )}
    </DropdownSectionItem>
  );
};

SelectOption.displayName = "DS.Select.Option";

export default SelectOption;
