import React, { useState } from "react";

import { Spinner } from "components/Spinner";
import { Eye } from "components/icons/generated";
import { ConfigElement, ConfigType } from "types/generated";
import CodeEditor from "components/CodeEditor";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { COMPUTED_VALUE } from "constants/environment";
import Icon from "ds/components/Icon";

import { toBlob } from "../helpers";
import EnvironmentVariable from "./EnvironmentVariable";
import FileMount from "./FileMount";
import styles from "../styles.module.css";

type ConfigElementComponentProps = ConfigElement & {
  children?: React.ReactNode;
};

const ConfigElementComponent = (props: ConfigElementComponentProps) => {
  const { id, children, runtime = false, type, value, writeOnly } = props;

  const computedValue = runtime ? COMPUTED_VALUE : value && value.replace(/ /g, "\u00a0");

  const [fileBody, setFileBody] = useState<string>("");
  const [editorBody, setEditorBody] = useState<string>("");
  const [isPreviewLoading, setLoading] = useState(false);

  const { onError, reportError } = useTypedContext(FlashContext);

  const handleEditorChange = (value?: string) => {
    setEditorBody(value || "");
  };

  const handlePreview = () => {
    const asyncFn = async () => {
      if (fileBody) {
        setFileBody("");
        setEditorBody("");
      } else if (value) {
        const preview = toBlob(value);

        if (preview.size > 524288) {
          return reportError({
            message: `The file is too large to preview (${preview.size / 1000}kB)`,
          });
        }

        setLoading(true);

        try {
          const result = await preview.text();
          setFileBody(result);
          setEditorBody(result);
        } catch (e) {
          onError(e);
        } finally {
          setLoading(false);
        }
      }

      return undefined;
    };

    void asyncFn();
  };

  const previewButtons = (
    <button className={styles.variablePreview} onClick={handlePreview}>
      {!fileBody && !isPreviewLoading && <Icon src={Eye} />}
      {isPreviewLoading && <Icon src={Spinner} />}
      {(fileBody && "Close preview") || "Preview"}
    </button>
  );

  return (
    <div className={styles.variable}>
      {type === ConfigType.EnvironmentVariable && (
        <EnvironmentVariable name={id} value={computedValue} />
      )}
      {type === ConfigType.FileMount && (
        <FileMount
          relativePath={id}
          value={value}
          writeOnly={writeOnly}
          previewButtons={previewButtons}
        />
      )}
      {children}
      {fileBody && (
        <CodeEditor
          className={styles.variablePreviewEditor}
          body={editorBody}
          onChange={handleEditorChange}
          language="rego"
          readOnly
        />
      )}
    </div>
  );
};

export default ConfigElementComponent;
