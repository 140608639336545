import { useEffect, useRef, useState } from "react";
import cx from "classnames";

import Box from "ds/components/Box";
import Toggle from "ds/components/Toggle";
import CollapsiblePanel from "components/CollapsiblePanel";
import { EntityChangeType } from "types/generated";
import TextEllipsis from "ds/components/TextEllipsis";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import { useToggle } from "hooks/useToggle";
import { ArrowRight } from "components/icons/generated";
import Icon from "ds/components/Icon";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTriggerNew";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";

import ChangeTypeIcon from "../../ChangeTypeIcon";
import styles from "./styles.module.css";
import RunChangesResourcesDiff from "../../ResourcesDiff";
import { EntityChangeWithId } from "../types";
import { getChangeType } from "../../helpers";
import ReplacementIndicator from "./ReplacementIndicator";
import EntityChangeResultBadge from "../EntityChangeResultBadge";

type RunChangesListItemElementProps = {
  item: EntityChangeWithId;
  onCheckItem: (item: EntityChangeWithId, checked: boolean) => unknown;
  checked: boolean;
  setRowHeight: (size: number) => void;
  selectable: boolean;
};

const RunChangesListItemElement = ({
  item,
  onCheckItem,
  checked,
  setRowHeight,
  selectable,
}: RunChangesListItemElementProps) => {
  const [isCollapsed, toggle] = useToggle(true);
  const [editorHeight, setEditorHeight] = useState(100);

  const handleCheckItem = () => {
    onCheckItem(item, !checked);
  };

  const { moved, result } = item.metadata;

  const changeType = getChangeType(item.metadata);

  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  }, [isCollapsed, editorHeight, setRowHeight]);

  return (
    <CollapsiblePanel isCollapsed={isCollapsed} onToggle={toggle} ref={rowRef}>
      <CollapsiblePanelHeader align="center" justify="between" padding="medium large" gap="medium">
        <Box align="center" gap="medium">
          <CollapsiblePanelToggleTrigger
            ariaLevel={3}
            ariaLabel={
              moved
                ? `${item.previousAddress}
                  moves to
                  ${item.address}`
                : item.address
            }
          />

          {selectable && (
            <Toggle
              variant="checkbox"
              id={`change-${item.id}`}
              onChange={handleCheckItem}
              checked={checked}
              dataTestId="run-changes-list-item-checkbox"
              ariaLabel={`Select ${item.address} for replanning`}
              value={item.address}
            />
          )}

          <ChangeTypeIcon type={item.metadata.type} moved={item.metadata.moved} />

          <TextEllipsis tooltip={item.address} tooltipWidthMode="maxWidthSm">
            {({ className, ...props }) => (
              <CollapsiblePanelTitle
                {...props}
                aria-hidden
                className={cx(className, styles.panelTitle)}
              >
                {moved ? (
                  <>
                    {item.previousAddress}
                    <Icon src={ArrowRight} color="secondary" />
                    {item.address}
                  </>
                ) : (
                  item.address
                )}
              </CollapsiblePanelTitle>
            )}
          </TextEllipsis>

          {(changeType === EntityChangeType.ReplaceCreateBeforeDestroy ||
            changeType === EntityChangeType.ReplaceDestroyBeforeCreate) && (
            <ReplacementIndicator changeType={changeType} />
          )}
        </Box>
        <EntityChangeResultBadge type={result} />
      </CollapsiblePanelHeader>
      {!isCollapsed && (
        <CollapsiblePanelContent padding="0 large large">
          <RunChangesResourcesDiff
            id={item.id}
            items={item.attributeChanges}
            changeType={changeType}
            editorHeight={editorHeight}
            setEditorHeight={setEditorHeight}
          />
        </CollapsiblePanelContent>
      )}
    </CollapsiblePanel>
  );
};

export default RunChangesListItemElement;
