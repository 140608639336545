import { FocusScope } from "react-aria";
import cx from "classnames";
import { forwardRef } from "react";

import Box from "ds/components/Box";
import { useToggle } from "hooks/useToggle";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import { Cross, Expand, SparksFilled } from "components/icons/generated";
import Markdown from "components/markdown/Markdown";
import useEscapeKeypress from "hooks/useEscapeKeyPress";

import styles from "./styles.module.css";
import BoxHeader from "./BoxHeader";
import SaturnheadBubble from "./SaturnheadBubble";
import { LoaderAiSummary } from "../LoaderAiSummary";

type RunSummarizationBoxProps = {
  loading?: boolean;
  content?: string;
  date?: number;
  clearSummary?: () => void;
};

const EMPTY_SUMMARY = "Looks like we couldn't generate a response for this run.";

const RunSummarizationBox = forwardRef<HTMLDivElement, RunSummarizationBoxProps>(
  function RunSummarizationBox({ content, date, loading }, externalRef) {
    const [fullScreen, toggleFullScreen] = useToggle(false);

    useEscapeKeypress(fullScreen, toggleFullScreen);

    if (loading) {
      return (
        <Box
          className={styles.runSummary}
          direction="column"
          align="center"
          justify="center"
          gap="large"
          style={{ minHeight: "20rem" }}
          fullWidth
        >
          <LoaderAiSummary />
        </Box>
      );
    }

    return (
      <FocusScope contain={fullScreen}>
        <Box
          className={cx(styles.runSummary, { [styles.fullScreen]: fullScreen })}
          fullWidth
          direction="column"
          gap="large"
        >
          <Box className={styles.summaryControls} gap="medium">
            {fullScreen ? (
              <ButtonIconNew
                icon={Cross}
                variant="secondary"
                onPress={() => toggleFullScreen(false)}
                size="small"
                aria-expanded={fullScreen}
              >
                Exit full screen mode
              </ButtonIconNew>
            ) : (
              <>
                <ButtonIconNew
                  icon={Expand}
                  variant="secondary"
                  onPress={() => toggleFullScreen(true)}
                  size="small"
                  aria-expanded={fullScreen}
                >
                  Full screen mode
                </ButtonIconNew>
                {/* TODO: pending BE endpoint implementation */}
                {/* <ButtonIconNew icon={Trash} variant="secondary" size="small" onPress={clearSummary}>
                Discard summary
              </ButtonIconNew> */}
              </>
            )}
          </Box>

          <BoxHeader title="AI summary" icon={SparksFilled} date={date} />

          <Box className={styles.bodyWrapper} ref={externalRef} scrollable fullWidth gap="large">
            <SaturnheadBubble />
            {/* tabIndex required for accessibility purposes. Scrollable elements should be keyboard accessible. */}
            <Box className={styles.bodyContent} scrollable fullWidth tabIndex={0}>
              <Markdown markup={content ?? EMPTY_SUMMARY} className={styles.summaryText} />
            </Box>
          </Box>
        </Box>
      </FocusScope>
    );
  }
);

export default RunSummarizationBox;
