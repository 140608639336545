import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import useAnalytics, { AnalyticsCommonProps } from "hooks/useAnalytics";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import { ChevronRight } from "components/icons/generated";
import Box from "ds/components/Box";

import { CollapsiblePanelContext } from "./Context";
import { CollapsiblePanelAriaLevel } from "./types";

type CollapsiblePanelToggleTriggerProps = AnalyticsCommonProps & {
  ariaLevel: CollapsiblePanelAriaLevel;
} & ({ ariaLabel: string; ariaLabelledby?: never } | { ariaLabel?: never; ariaLabelledby: string });

function CollapsiblePanelToggleTrigger({
  analyticsPage,
  analyticsTitle,
  analyticsProps,
  ariaLevel,
  ariaLabelledby,
  ariaLabel,
}: CollapsiblePanelToggleTriggerProps) {
  const { onToggle, isCollapsed, contentId } = useTypedContext(CollapsiblePanelContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const trackAnalytics = useCallback(() => {
    if (analyticsTitle) {
      trackSegmentAnalyticsEvent?.(analyticsTitle, { ...analyticsProps, visible: !isCollapsed });
    }
  }, [analyticsTitle, analyticsProps, trackSegmentAnalyticsEvent, isCollapsed]);

  const handleClickToggle = useCallback(() => {
    onToggle();
    trackAnalytics();
  }, [onToggle, trackAnalytics]);

  return (
    <Box align="center" role="heading" aria-level={ariaLevel}>
      <ButtonIconNew
        onPress={handleClickToggle}
        variant="ghost"
        aria-expanded={!isCollapsed}
        aria-controls={contentId}
        icon={ChevronRight}
        iconRotate={isCollapsed ? undefined : "90"}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledby}
      >
        {isCollapsed ? "Expand" : "Collapse"}
      </ButtonIconNew>
    </Box>
  );
}

export default CollapsiblePanelToggleTrigger;
