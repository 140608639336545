import { useMemo } from "react";

import useTypedContext from "hooks/useTypedContext";
import { EmptystateLinkColored, EmptystateMagnetColored } from "components/icons/generated";
import EmptyState from "ds/components/EmptyState";
import LoadingIndicator from "ds/components/LoadingIndicator";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Counter from "ds/components/Counter";
import ContextAttachment from "components/ContextAttachment";
import MissingDataBanner from "components/MissingDataBanner";

import { ModuleFormContext } from "../context";
import { ModuleCreationWizardStep } from "../types";
import useAttachedContexts from "../AttachContext/useAttachedContexts";
import NewModuleSummaryPanel from "./Panel";

type NewModuleSummaryAttachedContextsProps = {
  title: string;
  goToStepDisabled?: boolean;
};

const NewModuleSummaryAttachedContexts = ({
  title,
  goToStepDisabled,
}: NewModuleSummaryAttachedContextsProps) => {
  const { createdModuleId, formData } = useTypedContext(ModuleFormContext);
  const moduleLabels = useMemo(
    () => formData[ModuleCreationWizardStep.Details].labels.map((item) => item.value),
    [formData]
  );

  const {
    allContexts,
    autoAttachedContexts,
    manuallyAttachedContexts,
    hasData,
    loading,
    refetch,
    refetching,
  } = useAttachedContexts(createdModuleId);

  return (
    <>
      <NewModuleSummaryPanel
        name={title}
        count={allContexts.length}
        step={ModuleCreationWizardStep.Contexts}
        goToStepDisabled={goToStepDisabled}
      >
        {!loading && hasData && (
          <Box direction="column" gap="x-large">
            <Box direction="column" gap="medium">
              <Box grow="1" align="center" gap="medium">
                <Typography tag="span" variant="p-t6">
                  Manually attached
                </Typography>
                <Counter count={manuallyAttachedContexts.length} size="medium" />
              </Box>
              <Box direction="column" gap="medium">
                {manuallyAttachedContexts.length > 0 ? (
                  manuallyAttachedContexts.map((context) => (
                    <ContextAttachment key={context.id} context={context} readonly={true} />
                  ))
                ) : (
                  <EmptyState
                    padding="large"
                    icon={EmptystateLinkColored}
                    title="No contexts are attached to this module"
                    caption="Use contexts list to attach a context to module."
                  />
                )}
              </Box>
            </Box>
            <Box direction="column" gap="medium">
              <Box grow="1" align="center" gap="medium">
                <Typography tag="h6" variant="p-t6">
                  Auto-attached
                </Typography>
                <Counter count={autoAttachedContexts.length} />
              </Box>
              <Box direction="column" gap="medium">
                {autoAttachedContexts.length > 0 ? (
                  autoAttachedContexts.map((context) => (
                    <ContextAttachment
                      key={context.id}
                      context={context}
                      stackLabels={moduleLabels}
                      readonly
                    />
                  ))
                ) : (
                  <EmptyState
                    padding="large"
                    icon={EmptystateMagnetColored}
                    title="No contexts are auto-attached to this module"
                    caption="Use auto-attach label to attach context automatically."
                  />
                )}
              </Box>
            </Box>
          </Box>
        )}

        {allContexts.length === 0 && loading && (
          <Box padding="large" justify="center">
            <LoadingIndicator size="x-large" loading />
          </Box>
        )}

        {allContexts.length === 0 && !hasData && (
          <MissingDataBanner
            text="Couldn't load attached contexts, please refresh or come back later"
            refreshHandler={refetch}
            refreshLoading={refetching}
          />
        )}
      </NewModuleSummaryPanel>
    </>
  );
};

export default NewModuleSummaryAttachedContexts;
