import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Box from "ds/components/Box";
import DocumentationIconButton from "components/DocumentationIconButton";
import PageWrapper from "components/PageWrapper";
import { getDocsUrl } from "utils/getDocsUrl";

type LimitsPageLayoutProps = {
  children: ReactNode;
};

const LimitsPageLayout = ({ children }: LimitsPageLayoutProps) => {
  return (
    <>
      <ViewHeader firstLevel>
        <Box fullWidth justify="between" align="center">
          <ViewHeaderTitle tag="h2">Artificial Intelligence</ViewHeaderTitle>

          <ViewHeaderWrapper direction="row">
            {/* TODO: link to documentation */}
            <DocumentationIconButton
              href={getDocsUrl("/ai")}
              tooltipText="Go to AI documentation"
            />
          </ViewHeaderWrapper>
        </Box>
      </ViewHeader>
      <PageWrapper>{children}</PageWrapper>
    </>
  );
};

export default LimitsPageLayout;
