import { MouseEvent } from "react";

import Link from "ds/components/Link";

import styles from "./styles.module.css";

type FilterSectionContentActionsProps = {
  selectedSize: number;
  handleResetAll: (e: MouseEvent<HTMLButtonElement>) => void;
};

const FilterSectionContentActions = ({
  selectedSize,
  handleResetAll,
}: FilterSectionContentActionsProps) => {
  return (
    <span className={styles.listActions}>
      {/* // TODO: uncomment when backend optimize "select all" behavior */}
      {/* {!allSelected && (
          <Link className={styles.action} onClick={handleSelectAll}>
            Select all
          </Link>
        )}{" "} */}
      {/* {!allSelected && selectedValues.size > 0 && "or"}{" "} */}
      {selectedSize > 0 && (
        <Link className={styles.action} onClick={handleResetAll}>
          Clear all
        </Link>
      )}
    </span>
  );
};

export default FilterSectionContentActions;
