import {
  ReactNode,
  Ref,
  RefAttributes,
  RefObject,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

import Tree from "ds/components/Tree";
import DropdownSection from "ds/components/Dropdown/Section";
import SelectEmptyPlaceholder from "ds/components/Select/EmptyPlaceholder";
import { convertToTreeStructure, isNodeVisible, search, updateTreeRendering } from "utils/tree";

import TreeListNode from "./Node";
import { TreeListContext } from "./Context";
import { BaseTreeListItemProps, TreeListApi } from "./types";
import useKeyboardNavigation from "./useKeyboardNavigation";
import TreeListItem from "./Item";

type TreeListProps<T> = {
  id?: string;
  data: T[];
  idKey: keyof T;
  parentKey: keyof T;
  nameKey: keyof T;
  onChange?: (id: string) => void;
  onEscape?: () => void;
  renderItem?: (item: BaseTreeListItemProps<T>) => ReactNode;
  selectedKey?: string;
  searchQuery?: string;
  selectedRef?: RefObject<HTMLDivElement>;
  hiddenKeys?: string[];
  disabledKeys?: string[];
  maxDepth?: number;
};

// eslint-disable-next-line spacelift/display-name
const TreeList = <T extends object>(
  {
    id,
    selectedKey,
    searchQuery,
    data,
    idKey,
    parentKey,
    nameKey,
    onChange,
    onEscape,
    renderItem = TreeListItem,
    selectedRef,
    hiddenKeys,
    disabledKeys,
    maxDepth = Infinity,
  }: TreeListProps<T>,
  ref: Ref<TreeListApi>
) => {
  const { tree, flatList } = useMemo(
    () => convertToTreeStructure<T>(data, idKey, parentKey, nameKey),
    [data, idKey, nameKey, parentKey]
  );

  const [focusedKey, setFocusedKey] = useState<string | undefined>();

  const allKeysSet = useMemo(() => {
    const allKeys = flatList.map((item) => item.id);
    return new Set(allKeys);
  }, [flatList]);

  // TODO: think of some common "state" to avoid two separate sets
  const [expandedKeys, setExpandedKeys] = useState(allKeysSet);
  const [renderedKeys, setRenderedKeys] = useState(allKeysSet);

  useEffect(() => {
    if (searchQuery) {
      const matchedPaths = search(flatList, searchQuery);

      const { expandedNodes, renderedNodes } = updateTreeRendering(flatList, matchedPaths);

      setExpandedKeys(expandedNodes);
      setRenderedKeys(renderedNodes);
    } else {
      // If there's no search query, reset the expanded and rendered keys
      setExpandedKeys(allKeysSet);
      setRenderedKeys(allKeysSet);
    }
  }, [allKeysSet, flatList, searchQuery]);

  const finalRenderedKeys = useMemo(() => {
    const keysToRender = new Set(renderedKeys);
    if (hiddenKeys) {
      hiddenKeys.forEach((id) => {
        keysToRender.delete(id);
      });
    }

    return keysToRender;
  }, [hiddenKeys, renderedKeys]);

  const toggleKey = (key: string) => {
    setExpandedKeys((prev) => {
      const newKeys = new Set(prev);
      if (newKeys.has(key)) {
        newKeys.delete(key);
      } else {
        newKeys.add(key);
      }
      return newKeys;
    });
  };

  const visibleNodes = useMemo(
    () => flatList.filter((node) => isNodeVisible(node, finalRenderedKeys, expandedKeys)),
    [expandedKeys, finalRenderedKeys, flatList]
  );

  const treeDepth = useMemo(
    () => Math.max(...visibleNodes.map((node) => node.path.length)),
    [visibleNodes]
  );

  const handleKeyDown = useKeyboardNavigation({
    disabledKeys,
    expandedKeys,
    visibleNodes,
    onSelect: onChange,
    onFocus: setFocusedKey,
    onEscape,
    toggleKey,
  });

  const contextValue = useMemo(
    () => ({
      selectedKey,
      expandedKeys,
      searchQuery,
      renderedKeys: finalRenderedKeys,
      onChange,
      toggleKey,
      selectedRef,
      disabledKeys,
      onFocus: setFocusedKey,
      focusedKey,
      onKeyDown: handleKeyDown,
    }),
    [
      selectedKey,
      expandedKeys,
      searchQuery,
      finalRenderedKeys,
      onChange,
      selectedRef,
      disabledKeys,
      focusedKey,
      handleKeyDown,
    ]
  );

  useImperativeHandle(
    ref,
    () => ({
      focus: () => setFocusedKey(visibleNodes[0]?.id),
    }),
    [visibleNodes]
  );

  return (
    <TreeListContext.Provider value={contextValue}>
      <Tree
        id={id}
        compact
        gap="small"
        role="tree"
        data-maxdepth={treeDepth > maxDepth ? treeDepth : undefined}
      >
        {tree.map((node) => (
          <TreeListNode<T> key={node.id} node={node} renderItem={renderItem} isRoot />
        ))}

        {finalRenderedKeys.size === 0 && (
          <DropdownSection>
            <SelectEmptyPlaceholder />
          </DropdownSection>
        )}
      </Tree>
    </TreeListContext.Provider>
  );
};

interface TreeListComponent {
  <T extends object>(props: TreeListProps<T> & RefAttributes<TreeListApi>): JSX.Element;
  displayName?: string;
}

const TreeListWithRef = forwardRef(TreeList) as unknown as TreeListComponent;
TreeListWithRef.displayName = "DS.TreeList";

export default TreeListWithRef;
