import { useModal } from "@ebay/nice-modal-react";

import { Space } from "components/icons/generated";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import DescriptionDetails from "components/DescriptionDetails";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import { Policy } from "types/generated";
import { POLICY_TYPES_TITLES } from "constants/policy";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";

type PolicyDetailsDrawerProps = {
  onOpenFullDescription: () => void;
  policy: Policy;
};

const PolicyDetailsDrawer = createDrawer(
  ({ onOpenFullDescription, policy }: PolicyDetailsDrawerProps) => {
    const drawer = useModal();

    const handleOpenFullDescription = () => {
      onOpenFullDescription();
      drawer.hide();
    };

    return (
      <DrawerSimple>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Policy details" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody>
          <FormFieldViewText label="Name" value={policy.name} />
          <FormFieldViewText label="Type" value={POLICY_TYPES_TITLES[policy.type]} noTextEllipsis />

          <FormField label="Space">
            <MetaInfoListItem
              icon={Space}
              linkText={policy.spaceDetails.name}
              href={`/spaces/${policy.spaceDetails.id}`}
            />
          </FormField>

          <DescriptionDetails
            description={policy.description}
            onOpenFullDescription={handleOpenFullDescription}
          />
          <FormFieldTimestamp timestamp={policy.createdAt} label="Created at" />
          <FormFieldTimestamp timestamp={policy.updatedAt} label="Last updated" />

          {policy.labels.length > 0 && (
            <FormField label="Labels">
              <TagsList tags={policy.labels} alwaysExpanded />
            </FormField>
          )}
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showPolicyDetailsDrawer = createDrawerTrigger(PolicyDetailsDrawer);
