// TODO: Pending text from legal
export const LEGAL_TEXT = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra purus nec magna venenatis sagittis. Maecenas sodales mi id erat viverra tincidunt. Ut condimentum mauris diam, vitae euismod risus vestibulum id. Sed ac sagittis orci, vel tristique ante. Pellentesque efficitur quis ligula eget commodo. Integer vulputate neque eget lorem blandit, in commodo augue dapibus. Curabitur non lectus placerat, facilisis orci tristique, mattis erat. Nulla at metus tortor. In hac habitasse platea dictumst. In posuere ultricies vestibulum. Aenean quis dictum ex. Fusce nec dictum nibh. Donec quis urna a lacus malesuada vestibulum.

Cras libero nibh, accumsan et erat volutpat, posuere luctus felis. Fusce id orci et lorem laoreet fringilla quis eget lorem. Cras posuere sapien eget sem ultrices, quis imperdiet est lobortis. Praesent nec rhoncus nisl. Nunc viverra tincidunt sem, et ornare ligula condimentum eget. Nunc ac tincidunt enim, a dignissim mi. Sed ultrices porta massa a dapibus.

Integer nec augue hendrerit, lacinia lacus eget, posuere eros. Etiam at dignissim dolor, ut commodo sapien. Mauris sapien ipsum, consectetur sed felis ac, dapibus hendrerit libero. Quisque a ipsum dolor. Praesent ut nisi purus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at mollis eros, sed fermentum purus. Morbi leo nibh, vulputate eget fermentum nec, iaculis vitae tortor. Fusce sem erat, feugiat sit amet ornare eget, ornare eu justo.

Nulla molestie sagittis aliquam. Aliquam ut nunc turpis. Aliquam venenatis diam eu tempus rutrum. Duis vestibulum elit ac leo vestibulum blandit. In eu placerat tellus. Nullam consequat, urna at sodales lobortis, enim nibh venenatis magna, sit amet efficitur risus orci pellentesque urna. Ut vitae magna id nibh rhoncus euismod non quis lectus. Etiam ligula massa, imperdiet vel vestibulum id, facilisis eu lorem. Vestibulum non tempus ante. Cras consequat velit sit amet luctus efficitur. Morbi sit amet ullamcorper arcu, consectetur tempor libero. Sed diam ante, tincidunt non arcu sit amet, euismod pellentesque ipsum. Nullam odio purus, bibendum in mi quis, vulputate eleifend orci.

Sed scelerisque massa in orci viverra, nec cursus ex vestibulum. Nullam id felis sit amet nisl ultricies faucibus vel sed dui. Quisque tincidunt odio eget lectus porta, in congue quam vulputate. Praesent vitae lacinia leo. Nunc scelerisque mauris gravida augue dapibus, vel consequat diam ullamcorper. Aenean vel lobortis ipsum, bibendum aliquam tellus. Suspendisse blandit neque vel quam dapibus consectetur. Mauris at dolor nunc. Praesent vel nibh felis. Donec sed ullamcorper lacus. Curabitur facilisis diam nec dapibus maximus. In non dignissim arcu. Nam id placerat neque. Donec volutpat tortor a justo interdum maximus. Aliquam diam erat, lacinia tempor suscipit ut, congue quis nibh.`;
