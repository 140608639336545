import cx from "classnames";
import useLocalStorage from "@rehooks/local-storage";

import useTypedFlags from "hooks/useTypedFlags";

import { ReactComponent as LogoText } from "./logo-text.svg";
import { ReactComponent as LogoSymbol } from "./logo-symbol.svg";
import { ReactComponent as LogoSymbolFestive } from "./logo-symbol-festive.svg";
import styles from "./styles.module.css";
import Box from "../Box";

type SpaceliftLogoProps = {
  short?: boolean;
  className?: string;
};

const SpaceliftLogo = ({ short, className }: SpaceliftLogoProps) => {
  const { festiveMode } = useTypedFlags();
  const [isSnowEnabled] = useLocalStorage("snow", false);
  const hasSnowyVibe = festiveMode && isSnowEnabled;

  const LogoSymbolCurrent = hasSnowyVibe ? LogoSymbolFestive : LogoSymbol;
  return (
    <Box align="center" className={cx(styles.wrapper, short && styles.short, className)}>
      <div className={styles.logoTextWrapper}>
        <LogoText className={styles.logoText} />
      </div>
      <LogoSymbolCurrent
        className={cx(styles.logoSymbol, hasSnowyVibe && styles.logoSymbolFestive)}
      />
    </Box>
  );
};

SpaceliftLogo.displayName = "DS.SpaceliftLogo";

export default SpaceliftLogo;
