import { Controller, FormProvider, useForm } from "react-hook-form";
import { useModal } from "@ebay/nice-modal-react";

import DrawerForm from "ds/components/DrawerNew/Form";
import { WebhooksIntegration } from "types/generated";
import useCreateWebhook from "shared/Webhook/useCreateWebhook";
import { REFETCH_STACK_SETTINGS_QUERIES } from "views/Stack/Settings/constants";
import useUpdateWebhook from "shared/Webhook/useUpdateWebhook";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { validateRequiredURL } from "utils/formValidators";
import SecretInput from "ds/components/SecretInput";
import Toggle from "ds/components/Toggle";
import Typography from "ds/components/Typography";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import ButtonNew from "ds/components/Button/New";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import { getDrawerFormFix } from "utils/css";

import { getFormDefaultValues, mapCreateWebhookInput } from "./helpers";

type StackSettingsIntegrationsWebhooksCreateFormProps = {
  webhook?: WebhooksIntegration;
  stackId: string;
};

export type WebhookFormFields = {
  endpoint: string;
  secret: string;
  enabled: boolean;
};

const StackSettingsIntegrationsWebhooksCreateFormDrawer = createDrawer(
  ({ webhook, stackId }: StackSettingsIntegrationsWebhooksCreateFormProps) => {
    const isEditMode = !!webhook?.id;
    const drawer = useModal();

    const builderForm = useForm<WebhookFormFields>({
      defaultValues: getFormDefaultValues(webhook, isEditMode),
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      formState: { errors, isValid, isDirty },
    } = builderForm;

    const { createWebhook, loading: creatingWebhook } = useCreateWebhook({
      refetchQueries: REFETCH_STACK_SETTINGS_QUERIES,
    });

    const { updateWebhook, loading: updatingWebhook } = useUpdateWebhook({
      refetchQueries: REFETCH_STACK_SETTINGS_QUERIES,
    });

    const onCreateSubmit = (formData: WebhookFormFields) => {
      createWebhook({
        entityId: stackId,
        input: mapCreateWebhookInput(formData),
        successCallback: () => {
          drawer.hide();
        },
      });
    };

    const onUpdateSubmit = (formData: WebhookFormFields) => {
      if (!isEditMode) {
        return;
      }

      updateWebhook({
        webhookId: webhook.id,
        entityId: stackId,
        input: mapCreateWebhookInput(formData),
        successCallback: () => {
          drawer.hide();
        },
      });
    };

    const onSubmit = (formData: WebhookFormFields) => {
      if (isEditMode) {
        onUpdateSubmit(formData);
      } else {
        onCreateSubmit(formData);
      }
    };

    return (
      <DrawerForm isDirty={isEditMode && isDirty}>
        <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
          <FormProvider {...builderForm}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title={isEditMode ? "Edit webhook" : "Create webhook"} />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight>
              <Banner variant="info">
                You can set up a webhook integration to ensure that a POST request is sent every
                time an event of interest occurs within the scope of this stack. Make sure to set a
                secret so that you're able to verify that the request comes from us!
              </Banner>

              <Box gap="large" direction="column" margin="large 0 0">
                <FormField label="Endpoint" error={errors?.endpoint?.message} noMargin>
                  {({ ariaInputProps }) => (
                    <Input
                      placeholder="Full endpoint to send a POST request to"
                      error={!!errors?.endpoint}
                      {...register("endpoint", {
                        setValueAs: (value: string) => value.trim(),
                        validate: validateRequiredURL({
                          requiredStringError: "Endpoint is required",
                          urlError: "Endpoint must be a valid URL",
                        }),
                      })}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>

                <FormField label="Secret" error={errors?.secret?.message} noMargin>
                  {({ ariaInputProps }) => (
                    <SecretInput
                      placeholder="Secret to verify payload"
                      error={!!errors?.secret}
                      {...register("secret")}
                      {...ariaInputProps}
                    />
                  )}
                </FormField>

                <Controller
                  name="enabled"
                  render={({ field }) => (
                    <Toggle variant={"switch"} onChange={field.onChange} checked={field.value}>
                      <Typography tag="span" variant="p-t7">
                        Enabled
                      </Typography>
                    </Toggle>
                  )}
                />
              </Box>

              <DrawerFooter>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <ButtonNew
                    variant="primary"
                    type="submit"
                    disabled={!isValid || !isDirty || creatingWebhook || updatingWebhook}
                    loading={creatingWebhook || updatingWebhook}
                  >
                    {isEditMode ? "Save" : "Create"}
                  </ButtonNew>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </FormProvider>
        </form>
      </DrawerForm>
    );
  }
);

export const showStackSettingsIntegrationsWebhooksCreateFormDrawer = createDrawerTrigger(
  StackSettingsIntegrationsWebhooksCreateFormDrawer
);
