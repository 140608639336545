import cx from "classnames";
import { useCallback, useId } from "react";

import TextEllipsis from "ds/components/TextEllipsis";
import ToggleNew from "ds/components/ToggleNew";
import Typography from "ds/components/Typography";
import { SelectOption } from "ds/components/Select/types";

import styles from "./styles.module.css";

type FilterOptionProps = {
  value: string;
  label: string;
  postfix?: string | number;
  onChange?: (value: string, option?: SelectOption) => void;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
};

const FilterOption = (props: FilterOptionProps) => {
  const { disabled, value, label, onChange, checked, postfix, className } = props;

  // If the label comes as an empty string (""), it is replaced with a visual placeholder.
  const displayLabel = label || "Empty";

  const id = useId();

  const handleOnChange = useCallback(() => {
    onChange?.(value, {
      value,
      label,
    });

    return undefined;
  }, [label, onChange, value]);

  return (
    <ToggleNew
      className={cx(styles.item, className)}
      variant="checkbox"
      id={id}
      onChange={handleOnChange}
      isSelected={checked}
      isDisabled={disabled}
      align="center"
    >
      <TextEllipsis
        tooltip={displayLabel}
        tooltipPlacement="top-end"
        tooltipWidthMode="maxWidthXl"
        delay={400}
        mode="single"
      >
        {(ellipsisProps) => (
          <Typography {...ellipsisProps} variant="p-body3" tag="span">
            {displayLabel}
          </Typography>
        )}
      </TextEllipsis>

      {postfix !== undefined && (
        <Typography variant="p-body3" tag="span" className={styles.postfix}>
          {postfix}
        </Typography>
      )}
    </ToggleNew>
  );
};

export default FilterOption;
