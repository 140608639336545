import cx from "classnames";
import { forwardRef, MouseEvent, ReactNode, ForwardedRef } from "react";

import { Cross } from "components/icons/generated";
import { IconComponent } from "types/Icon";
import { getTagIconAndRenderValue } from "utils/tags";

import ButtonIcon from "../ButtonIcon";
import Icon from "../Icon";
import TextEllipsis from "../TextEllipsis";
import styles from "./styles.module.css";

type TagProps = {
  tag: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  onRemove?: () => void;
  icon?: IconComponent;
  fullWidth?: boolean;
};

const Tag = forwardRef(function Tag(
  { tag, onClick, className, onRemove, icon, fullWidth = false, ...restProps }: TagProps,
  ref: React.ForwardedRef<HTMLButtonElement | HTMLDivElement>
) {
  const { isTextTag, tagIcon, tagValue } = getTagIconAndRenderValue(tag, icon);

  const isClickable = !!onClick && !onRemove;

  const fullClassName = cx(
    styles.tag,
    {
      [styles.action]: !!onClick,
      [styles.withRemoveIcon]: !!onRemove,
      [styles.fullWidth]: fullWidth,
    },
    className
  );

  const content = (
    <>
      {tagIcon && <Icon src={tagIcon} noShrink />}
      {isTextTag && (
        <TextEllipsis tooltip={tag} tooltipWidthMode="maxWidthSm">
          {(props) => <span {...props}>{tagValue}</span>}
        </TextEllipsis>
      )}
      {!isTextTag && tag}

      {onRemove && (
        <ButtonIcon icon={Cross} onClick={onRemove} variant="ghost">
          Delete
        </ButtonIcon>
      )}
    </>
  );

  if (isClickable) {
    return (
      <button
        ref={ref as ForwardedRef<HTMLButtonElement>}
        onClick={onClick}
        type="button"
        className={fullClassName}
        {...restProps}
      >
        {content}
      </button>
    );
  }

  return (
    <div ref={ref as ForwardedRef<HTMLDivElement>} className={fullClassName} {...restProps}>
      {content}
    </div>
  );
});

Tag.displayName = "DS.Tag";

export default Tag;
